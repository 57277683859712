
























import KCard from '@/@core/components/other/KCard.vue'
import { isMobile } from '@/@core/helpers/useBreakpoint'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { KCard },
    name: 'AuthLayout',
    setup() {
        //

        return {
            isMobile
        }
    }
})
